import React from "react";
import Link from "gatsby-link";
import icon_close from "../assets/icon-close.svg";
import Spinner from "react-svg-spinner";
import ThankYou from "./ThankYou";
import FormValidator from "../utils/formValidator";

class BeitrittsForm extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "anrede",
        method: "isEmpty",
        validWhen: false,
        message: "Wie dürfen wir Sie anreden?",
      },
      {
        field: "email",
        method: "isEmpty",
        validWhen: false,
        message: "Bitte Ihre E-Mail eingeben.",
      },
      {
        field: "phone",
        method: "isEmpty",
        validWhen: false,
        message: "Bitte Ihre Telefonnummer eingeben.",
      },
      {
        field: "email",
        method: "isEmail",
        validWhen: true,
        message: "Das sieht komisch aus?!",
      },
      {
        field: "phone",
        method: this.isPhone,
        validWhen: true,
        message: "Das sieht nicht nach einer Telefonnummer aus.",
      },
      {
        field: "vorname",
        method: "isEmpty",
        validWhen: false,
        message: "Bitte Ihren Vorname angeben.",
      },
      {
        field: "vorname",
        method: "isLength",
        args: [{ min: 2, max: 50 }],
        validWhen: true,
        message: "Das sieht komisch aus?!",
      },
      {
        field: "nachname",
        method: "isEmpty",
        validWhen: false,
        message: "Bitte Ihren Nachnamen angeben.",
      },
      {
        field: "nachname",
        method: "isLength",
        args: [{ min: 3, max: 50 }],
        validWhen: true,
        message: "Das sieht komisch aus?!",
      },
      {
        field: "street",
        method: "isEmpty",
        validWhen: false,
        message: "Bitte Strasse & Hausnummer eingeben.",
      },
      {
        field: "city",
        method: "isEmpty",
        validWhen: false,
        message: "Bitte PLZ & Ort eingeben.",
      },
      {
        field: "amount",
        method: "isNumeric",
        validWhen: true,
        message: "Bitte eine Zahl eingeben.",
      },
      {
        field: "amount",
        method: this.greaterThan25,
        validWhen: true,
        message: "Der Mindestbeitrag ist 25 EURO.",
      },
      {
        field: "approved",
        method: this.isChecked_approved,
        validWhen: true,
        message: "Bitte bestätigen.",
      },
      {
        field: "accountOwner",
        method: "isEmpty",
        validWhen: false,
        message: "Bitte Kontoinhaber eingeben.",
      },
      {
        field: "bank",
        method: "isEmpty",
        validWhen: false,
        message: "Bitte Bankinstitut eingeben.",
      },
      {
        field: "iban",
        method: "isEmpty",
        validWhen: false,
        message: "Bitte IBAN eingeben.",
      },
      {
        field: "iban",
        method: this.isIban,
        validWhen: true,
        message: "Sieht nicht wie eine IBAN aus.",
      },
      {
        field: "bic",
        method: "isEmpty",
        validWhen: false,
        message: "Bitte BIC eingeben.",
      },
      {
        field: "bic",
        method: this.isBic,
        validWhen: true,
        message: "Sieht nicht wie eine BIC aus.",
      },
      {
        field: "privacy",
        method: this.isChecked_privacy,
        validWhen: true,
        message: "Bitte bestätigen.",
      },
    ]);

    this.state = {
      anrede: "",
      email: "",
      phone: "",
      vorname: "",
      nachname: "",
      street: "",
      city: "",
      amount: 0,
      approved: false,
      accountOwner: "",
      bank: "",
      iban: "",
      bic: "",
      privacy: false,
      validation: this.validator.valid(),
      submitting: false,
      thankYou: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);

    this.submitted = false;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  greaterThan25 = () => {
    return this.state.amount > 24;
  };

  isChecked_approved = () => {
    return this.state.approved;
  };

  isIban = () => {
    return /^AL\d{10}[0-9A-Z]{16}$|^AD\d{10}[0-9A-Z]{12}$|^AT\d{18}$|^BH\d{2}[A-Z]{4}[0-9A-Z]{14}$|^BE\d{14}$|^BA\d{18}$|^BG\d{2}[A-Z]{4}\d{6}[0-9A-Z]{8}$|^HR\d{19}$|^CY\d{10}[0-9A-Z]{16}$|^CZ\d{22}$|^DK\d{16}$|^FO\d{16}$|^GL\d{16}$|^DO\d{2}[0-9A-Z]{4}\d{20}$|^EE\d{18}$|^FI\d{16}$|^FR\d{12}[0-9A-Z]{11}\d{2}$|^GE\d{2}[A-Z]{2}\d{16}$|^DE\d{20}$|^GI\d{2}[A-Z]{4}[0-9A-Z]{15}$|^GR\d{9}[0-9A-Z]{16}$|^HU\d{26}$|^IS\d{24}$|^IE\d{2}[A-Z]{4}\d{14}$|^IL\d{21}$|^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^[A-Z]{2}\d{5}[0-9A-Z]{13}$|^KW\d{2}[A-Z]{4}22!$|^LV\d{2}[A-Z]{4}[0-9A-Z]{13}$|^LB\d{6}[0-9A-Z]{20}$|^LI\d{7}[0-9A-Z]{12}$|^LT\d{18}$|^LU\d{5}[0-9A-Z]{13}$|^MK\d{5}[0-9A-Z]{10}\d{2}$|^MT\d{2}[A-Z]{4}\d{5}[0-9A-Z]{18}$|^MR13\d{23}$|^MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}$|^MC\d{12}[0-9A-Z]{11}\d{2}$|^ME\d{20}$|^NL\d{2}[A-Z]{4}\d{10}$|^NO\d{13}$|^PL\d{10}[0-9A-Z]{,16}n$|^PT\d{23}$|^RO\d{2}[A-Z]{4}[0-9A-Z]{16}$|^SM\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^SA\d{4}[0-9A-Z]{18}$|^RS\d{20}$|^SK\d{22}$|^SI\d{17}$|^ES\d{22}$|^SE\d{22}$|^CH\d{7}[0-9A-Z]{12}$|^TN59\d{20}$|^TR\d{7}[0-9A-Z]{17}$|^AE\d{21}$|^GB\d{2}[A-Z]{4}\d{14}$/.test(
      this.state.iban
    );
  };

  // using this https://regex101.com/r/CAVex8/143
  isPhone = () => {
    //eslint-disable-next-line
    return /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/.test(
      this.state.phone
    );
  };

  isBic = () => {
    return /^([A-Z]{6}[A-Z2-9][A-NP-Z1-2])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(
      this.state.bic.toUpperCase()
    );
  };

  isChecked_privacy = () => {
    return this.state.privacy;
  };

  handleFormSubmit = (event) => {
    function encode(data) {
      return Object.keys(data)
        .map(
          (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
    }
    function sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    }

    event.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    const form = event.target;

    if (validation.isValid) {
      // handle actual form submission here
      this.setState({ submitting: true });
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state,
        }),
      })
        .then(
          sleep(2500).then(() => {
            this.setState({ thankYou: true });
          })
        )
        .catch((error) => alert(error));
    }
  };

  render() {
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation; // otherwise just use what's in state

    return (
      <div
        className={
          "beitrittsForm" + (this.props.formVisible ? " visible" : " hidden")
        }>
        {this.state.thankYou && (
          <ThankYou anrede={this.state.anrede} nachname={this.state.nachname} />
        )}

        <form
          name="Online Beitrittsformular"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleFormSubmit}
          className={this.state.thankYou ? "hidden" : ""}>
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input
            type="hidden"
            name="form-name"
            value="Online Beitrittsformular"
          />

          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleInputChange} />
            </label>
          </p>

          <button className="close" onClick={this.props.hideForm}>
            <img src={icon_close} alt="close" />
          </button>
          <h3>Beitrittserklärung</h3>
          <p>
            Hiermit erkläre ich meinen Bei&shy;tritt zur „Gesell&shy;schaft der
            Freunde und För&shy;derer des Städ&shy;tischen Luisen-Gymnasiums zu
            Düssel&shy;dorf e. V.“ und ver&shy;pflich&shy;te mich, den
            satzungs&shy;gemäßen Jahres-Bei&shy;trag in Höhe von{" "}
            {this.state.amount > 24 ? this.state.amount : "25"} EURO zu leisten.
          </p>
          <div className="form_beitritt">
            <div className="inputblock">
              <label htmlFor="anrede">Anrede</label>
              <select
                id="anrede"
                name="anrede"
                className={validation.anrede.isInvalid ? "has-error" : ""}
                onChange={this.handleInputChange}>
                <option defaultValue value="">
                  bitte auswählen
                </option>
                <option value="Herr">Herr</option>
                <option value="Frau">Frau</option>
              </select>
              {validation.anrede.isInvalid && (
                <p className="help-block">
                  <span>{validation.anrede.message}</span>
                </p>
              )}
            </div>

            {/* keeping a free spot after "Anrede" */}
            <div className="inputblock">{/* empty */}</div>

            <div className="inputblock">
              <label htmlFor="vorname">Vorname</label>
              <input
                id="vorname"
                type="vorname"
                name="vorname"
                className={
                  validation.vorname.isInvalid
                    ? "has-error no-ios-styling"
                    : "no-ios-styling"
                }
                onChange={this.handleInputChange}
              />
              {validation.vorname.isInvalid && (
                <p className="help-block">
                  <span>{validation.vorname.message}</span>
                </p>
              )}
            </div>

            <div className="inputblock">
              <label htmlFor="nachname">Nachname</label>
              <input
                id="nachname"
                type="nachname"
                name="nachname"
                className={
                  validation.nachname.isInvalid
                    ? "has-error no-ios-styling"
                    : "no-ios-styling"
                }
                onChange={this.handleInputChange}
              />
              {validation.nachname.isInvalid && (
                <p className="help-block">
                  <span>{validation.nachname.message}</span>
                </p>
              )}
            </div>

            <div className="inputblock">
              <label htmlFor="street">Straße und Hausnummer</label>
              <input
                id="street"
                type="street"
                name="street"
                className={
                  validation.street.isInvalid
                    ? "has-error no-ios-styling"
                    : "no-ios-styling"
                }
                onChange={this.handleInputChange}
              />
              {validation.street.isInvalid && (
                <p className="help-block">
                  <span>{validation.street.message}</span>
                </p>
              )}
            </div>

            <div className="inputblock">
              <label htmlFor="city">PLZ und Ort</label>
              <input
                id="city"
                type="city"
                name="city"
                className={
                  validation.city.isInvalid
                    ? "has-error no-ios-styling"
                    : "no-ios-styling"
                }
                onChange={this.handleInputChange}
              />
              {validation.city.isInvalid && (
                <p className="help-block">
                  <span>{validation.city.message}</span>
                </p>
              )}
            </div>

            <div className="inputblock">
              <label htmlFor="email">E-Mail Adresse</label>
              <input
                id="email"
                type="email"
                name="email"
                className={
                  validation.email.isInvalid
                    ? "has-error no-ios-styling"
                    : "no-ios-styling"
                }
                onChange={this.handleInputChange}
              />
              {validation.email.isInvalid && (
                <p className="help-block">
                  <span>{validation.email.message}</span>
                </p>
              )}
            </div>

            <div className="inputblock">
              <label htmlFor="phone">Telefonnummer</label>
              <input
                id="phone"
                type="tel"
                name="phone"
                className={
                  validation.phone.isInvalid
                    ? "has-error no-ios-styling"
                    : "no-ios-styling"
                }
                onChange={this.handleInputChange}
              />
              {validation.phone.isInvalid && (
                <p className="help-block">
                  <span>{validation.phone.message}</span>
                </p>
              )}
            </div>
          </div>

          <h3>Einzugsermächtigung</h3>
          <p>
            Hiermit ermäch&shy;tige ich die „Gesell&shy;schaft der Freunde und
            För&shy;derer des Städ&shy;tischen Luisen-Gym&shy;nasiums zu
            Düssel&shy;dorf e. V.“ den Bei&shy;trag von{" "}
            <strong>
              {this.state.amount > 24 ? this.state.amount : "25"} EURO
            </strong>{" "}
            jähr&shy;lich von meinem Konto abzu&shy;buchen.
          </p>

          <div className="form_einzug">
            <div className="form_einzug__amount">
              <label htmlFor="amount">Jahresbeitrag</label>
              <div className="vertical-center">
                <input
                  id="amount"
                  //defaultValue="25"
                  type="number"
                  name="amount"
                  className={
                    validation.amount.isInvalid
                      ? "has-error no-ios-styling"
                      : "no-ios-styling"
                  }
                  onChange={this.handleInputChange}
                />
                <p>EURO</p>
                <p className="min25hint">
                  Der Mindest-Jahres-Beitrag beträgt 25 EURO.
                  <br />
                  Höhere Beiträge sind auch willkommen!
                  <br />
                  Korrigieren Sie gerne nach oben.
                </p>
              </div>
              {validation.amount.isInvalid && (
                <p className="help-block">
                  <span>{validation.amount.message}</span>
                </p>
              )}
            </div>
            <div className="form_einzug__approved">
              <label htmlFor="approved">
                {/* https://tympanus.net/codrops/2013/10/15/animated-checkboxes-and-radio-buttons-with-svg/ */}
                <input
                  type="checkbox"
                  id="approved"
                  name="approved"
                  checked={this.state.approved}
                  className={validation.approved.isInvalid ? "has-error" : ""}
                  onChange={this.handleInputChange}
                />
                <p>Einzugsermächtigung wird erteilt</p>
                {validation.approved.isInvalid && (
                  <p className="help-block">
                    <span>{validation.approved.message}</span>
                  </p>
                )}
              </label>
            </div>
            <div className="form_einzug__data">
              <div className="form_einzug__data___left">
                <label htmlFor="accountOwner">
                  Kontoinhaber
                  <input
                    id="accountOwner"
                    type="accountOwner"
                    name="accountOwner"
                    className={
                      validation.accountOwner.isInvalid
                        ? "has-error no-ios-styling"
                        : "no-ios-styling"
                    }
                    onChange={this.handleInputChange}
                  />
                  {validation.accountOwner.isInvalid && (
                    <p className="help-block">
                      <span>{validation.accountOwner.message}</span>
                    </p>
                  )}
                </label>
                <label htmlFor="bank">
                  Bankinstitut
                  <input
                    id="bank"
                    type="bank"
                    name="bank"
                    className={
                      validation.bank.isInvalid
                        ? "has-error no-ios-styling"
                        : "no-ios-styling"
                    }
                    onChange={this.handleInputChange}
                  />
                  {validation.bank.isInvalid && (
                    <p className="help-block">
                      <span>{validation.bank.message}</span>
                    </p>
                  )}
                </label>
              </div>
              <div className="form_einzug__data___right">
                <label htmlFor="iban">
                  IBAN
                  <input
                    id="iban"
                    type="iban"
                    name="iban"
                    className={
                      validation.iban.isInvalid
                        ? "has-error no-ios-styling"
                        : "no-ios-styling"
                    }
                    onChange={this.handleInputChange}
                  />
                  {validation.iban.isInvalid && (
                    <p className="help-block">
                      <span>{validation.iban.message}</span>
                    </p>
                  )}
                </label>
                <label htmlFor="bic">
                  BIC
                  <input
                    id="bic"
                    type="bic"
                    name="bic"
                    className={
                      validation.bic.isInvalid
                        ? "has-error no-ios-styling"
                        : "no-ios-styling"
                    }
                    onChange={this.handleInputChange}
                  />
                  {validation.bic.isInvalid && (
                    <p className="help-block">
                      <span>{validation.bic.message}</span>
                    </p>
                  )}
                </label>
              </div>
            </div>
            <div className="form_einzug__privacy">
              <label htmlFor="privacy">
                {/* https://tympanus.net/codrops/2013/10/15/animated-checkboxes-and-radio-buttons-with-svg/ */}
                <input
                  type="checkbox"
                  id="privacy"
                  name="privacy"
                  checked={this.state.privacy}
                  className={validation.privacy.isInvalid ? "has-error" : ""}
                  onChange={this.handleInputChange}
                />
                <p>
                  Ich habe die{" "}
                  <Link to="/datenschutz">
                    Daten&shy;schutz-In&shy;for&shy;ma&shy;tio&shy;nen
                  </Link>{" "}
                  zur Kennt&shy;nis ge&shy;nom&shy;men
                </p>
                {validation.privacy.isInvalid && (
                  <p className="help-block">
                    <span>{validation.privacy.message}</span>
                  </p>
                )}
              </label>
            </div>
          </div>
          {!validation.isValid && (
            <p className="help-block-global">
              ⚠️ Bitte kontrollieren Sie Ihre Eingaben noch einmal!
            </p>
          )}
          <button
            className={
              "submit" +
              (this.state.validation.isValid ? "" : " error") +
              (this.state.submitting ? " submitting" : "")
            }
            type="submit"
            //onClick={this.handleFormSubmit}
            //disabled={!this.state.validation.isValid}
          >
            {this.state.submitting && <Spinner size="24px" color="white" />}
            {!this.state.submitting && <span>abschicken</span>}
          </button>
        </form>
      </div>
    );
  }
}

export default BeitrittsForm;
