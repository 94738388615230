import React from "react";
import icon_thankYou from "../assets/icon_thankYou.svg";

const ThankYou = (props) => {
  return (
    <div className="thankYou">
      <img src={icon_thankYou} alt="Thank you" />
      <h3>
        Danke, {props.anrede}&nbsp;{props.nachname}!
      </h3>
      <p>
        Wir haben Ihre Daten erhalten und freuen uns über Ihre Unterstützung.
      </p>
    </div>
  );
};

export default ThankYou;
