import React from "react";

import Layout from "../components/Layout";

import step1_icon_download from "../assets/step1_icon_download.svg";
import step2_icon_print from "../assets/step2_icon_print.svg";
import step3_icon_send from "../assets/step3_icon_send.svg";

import pdf from "../assets/FördervereinLuisen_Beitrittsformular.pdf";

import BeitrittsForm from "../components/BeitrittsForm";

// https://www.npmjs.com/package/react-obfuscate
import Obfuscate from "react-obfuscate";

class AntraegeInfos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formVisible: false,
      justShowThankYou: false,
    };

    this.showForm = this.showForm.bind(this);
    this.hideForm = this.hideForm.bind(this);
  }

  showForm() {
    this.setState({
      formVisible: true,
    });
  }

  hideForm() {
    this.setState({
      formVisible: false,
    });
  }

  render() {
    return (
      <Layout pageTitle={`Anträge & Infos`}>
        <div className="antraege-infos site-content_inner">
          <h2>Anträge &amp; Infos</h2>
          <p>
            Die Mit&shy;glied&shy;schaft ist ver&shy;bunden mit der Zah&shy;lung
            eines Mindest-Jahres-Beitrages von aktuell 25 Euro im
            Last&shy;schrift&shy;ver&shy;fahren.
            <br />
            Höhere Bei&shy;träge sind selbst&shy;ver&shy;ständ&shy;lich auch
            sehr will&shy;kommen.
          </p>
          <p>So werden Sie Mit&shy;glied:</p>
          <div className="three-steps">
            <div className="step step1">
              <a href={pdf} target="_blank" rel="noreferrer">
                <span className="step-number">1</span>
                <span className="step-icon">
                  <img src={step1_icon_download} alt="download" />
                </span>
                <p className="step-title">
                  Mitgliedsantrag
                  <br />
                  <span>herunterladen</span>
                </p>
              </a>
            </div>
            <div className="step step2">
              <span className="step-number">2</span>
              <span className="step-icon">
                <img src={step2_icon_print} alt="print" />
              </span>
              <p className="step-title">
                am Rechner ausfüllen
                <br />
                und dann ausdrucken
              </p>
            </div>
            <div className="step step3">
              <Obfuscate
                email="foerderverein@luisen-gymnasium.de"
                headers={{
                  subject: "Mitgliedsantrag von freunde-luisengymnasium.de",
                }}>
                <span className="step-number">3</span>
                <span className="step-icon">
                  <img src={step3_icon_send} alt="send" />
                </span>
                <p className="step-title">
                  unterschreiben und
                  <br />
                  <span>uns zuschicken</span>
                </p>
              </Obfuscate>
            </div>
          </div>
          <div className="form-link">
            <button onClick={this.showForm}>
              ..oder bequem per Online-Formular
            </button>
          </div>
          <BeitrittsForm
            formVisible={this.state.formVisible}
            // ich gebe die Funktion als prop mit
            hideForm={this.hideForm}
            justShowThankYou={this.justShowThankYou}
          />
          <p>
            Wir weisen an dieser Stelle darauf hin, dass unser
            Ge&shy;schäfts&shy;jahr dem Kalen&shy;der&shy;jahr ent&shy;spricht,
            und nicht, wie manch&shy;mal ange&shy;nommen, dem Schuljahr.
          </p>
          <p>
            Beitrags&shy;zahlun&shy;gen werden künf&shy;tig für das
            Ge&shy;schäfts&shy;jahr je&shy;weils am Jahres&shy;anfang
            fäl&shy;lig, bei Ein&shy;tritt für das Ein&shy;tritts&shy;jahr in
            voller Höhe und bei Aus&shy;tritt noch bis zum Ab&shy;lauf der
            Mit&shy;glied&shy;schaft. Diese endet ge&shy;mäß Satzung nicht
            auto&shy;matisch mit Be&shy;endi&shy;gung der
            Schul&shy;lauf&shy;bahn des Kindes, sondern nur durch
            schrift&shy;liche Austritts&shy;er&shy;klä&shy;rung mit einer
            Kündigungs&shy;frist von drei Monaten zum
            Kalender&shy;jahres&shy;ende.
          </p>
        </div>
      </Layout>
    );
  }
}

export default AntraegeInfos;
